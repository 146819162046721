<template>
  <div class="case-study-tile">
    <div class="case-study-tile__content col">
      <div class="case-study-tile__image-wrapper row flex-center">
        <UiImg
          :image="image"
          class="case-study-tile__image"
          :sizes="{ xs: '504' }"
          use-image-size
        />

        <component
          :is="isLinkValid(url) ? 't3-link' : 'div'"
          :to="url"
          :tabindex="-1"
          class="case-study-tile__mask col v-end"
        >
          <div class="case-study-tile__mask-wrap row">
            <ul class="case-study-tile__category-wrap row flex-wrap">
              <li class="case-study-tile__label grow">
                {{ labels[lang as keyof typeof labels] }}
              </li>

              <li
                v-for="category in categoryList"
                :key="category"
                class="case-study-tile__category"
              >
                <T3HtmlParser
                  class="case-study-tile__value"
                  :content="category"
                />
              </li>
            </ul>
          </div>
        </component>
      </div>

      <component
        :is="isLinkValid(url) ? 't3-link' : 'div'"
        v-if="header"
        :to="url"
        :tabindex="focusable ? 0 : -1"
        class="case-study-tile__title"
      >
        <T3HtmlParser v-if="header" tag="h3" :content="header" />
      </component>

      <T3HtmlParser
        v-if="bodytext"
        class="case-study-tile__text"
        :content="bodytext"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiCeCategoryBoxesItemProps } from '~/types'
import { UiImg } from '~ui/components'
import { isLinkValid } from '~ui/helpers/linkUtils'

const props = withDefaults(
  defineProps<
    UiCeCategoryBoxesItemProps & { lang: string; focusable?: boolean }
  >(),
  {
    focusable: true
  }
)

const labels = {
  pl: 'Kategoria',
  en: 'Category',
  de: 'Kategorie'
}

const categoryList = computed(() => {
  return (
    props.categories
      .filter((item) => item.category.featured)
      .map(({ category }) => category.name) || []
  )
})
</script>

<style lang="scss">
.case-study-tile {
  $base: &;

  position: relative;
  text-align: left;
  max-width: rem(536px);
  padding: rem(16px);
  margin-inline: auto;

  &__content {
    height: 100%;
    position: relative;
  }

  &__image-wrapper {
    background-color: color(wild-sand);
    border-radius: em(3px);
    overflow: hidden;
    position: relative;
    aspect-ratio: 16 / 10;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease-in-out 0.1s;
  }

  &__mask {
    position: absolute;
    inset: 0;
    background: linear-gradient(
      360deg,
      hsl(0 0% 0% / 36%) 10%,
      hsl(0 0% 50% / 0) 80%
    );
    padding: rem(20px);
    color: color(white);
    opacity: 0;
    transition: opacity 0.2s;

    &:is(:hover, :focus) {
      color: color(white);
      outline: none;
    }
  }

  &__image-wrapper:is(:hover, :focus-within) {
    & #{$base}__image {
      transform: scale(1.08);
    }

    & #{$base}__mask {
      opacity: 1;
    }
  }

  &__mask-wrap {
    position: relative;

    &::after {
      content: '';
      display: block;
      width: rem(20px);
      height: rem(20px);
      position: absolute;
      right: 0;
      bottom: 0;
      background-image: url('~ui/assets/icons/icon-arrow-right.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      filter: invert(1);
    }
  }

  &__category-wrap {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-right: em(25px);
  }

  &__category:not(:last-child) &__value::after {
    content: ',\00a0';
  }

  &__label {
    width: 100%;
    text-transform: uppercase;
    line-height: 1.5;
    font-size: rem(14px);
    font-weight: 500;
    opacity: 0.7;
  }

  &__value {
    font-size: rem(16px);
    font-weight: 500;
    margin-top: rem(5px);
    line-height: 1.2;
  }

  &__title {
    &:focus {
      outline: none;
    }

    & h3 {
      font-size: rem(24px);
      font-weight: 600;
      margin-top: rem(24px);
    }
  }

  &__text p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: rem(16px);
    font-weight: 300;
  }
}
</style>
